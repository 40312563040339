<template>
  <div class="container">
    <el-breadcrumb separator-class="el-icon-arrow-right" style="height: 40px;">
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '公司列表') }}</el-breadcrumb-item>
      <el-breadcrumb-item>{{ fun.getLanguageText($store.state.language, '添加公司') }}</el-breadcrumb-item>
    </el-breadcrumb>
    <el-form ref="ruleForm" :model="formData" :rules="rules" label-width="160px" status-icon>
      <el-form-item :label="fun.getLanguageText($store.state.language, '公司名称')" prop="companyName">
        <el-input v-model="formData.companyName" :disabled="$route.query.id"
          :placeholder="fun.getLanguageText($store.state.language, '请输入')"></el-input>
      </el-form-item>

      <el-form-item :label="fun.getLanguageText($store.state.language, '公司地址')" prop="companyAddr">
        <el-input v-model="formData.companyAddr" autocomplete="off"
          :placeholder="fun.getLanguageText($store.state.language, '请输入')"></el-input>
      </el-form-item>

      <el-form-item :label="fun.getLanguageText($store.state.language, '公司邮箱')" prop="companyEmail">
        <el-input v-model="formData.companyEmail" autocomplete="off"
          :placeholder="fun.getLanguageText($store.state.language, '请输入')"></el-input>
      </el-form-item>

      <el-form-item :label="fun.getLanguageText($store.state.language, '公司类型')" prop="companyType">
        <el-select v-model="formData.companyType" :disabled="is_disabled_company_type || $route.query.id"
          :placeholder="fun.getLanguageText($store.state.language, '请选择')" @change="changecompanyType">
          <el-option v-if="$store.state.roleId === 1" :label="fun.getLanguageText($store.state.language, '管理员')"
            value="-1"></el-option>
          <el-option :label="fun.getLanguageText($store.state.language, '客户')" value="1"></el-option>
          <el-option :label="fun.getLanguageText($store.state.language, '终端')" value="2"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item v-if="is_belong" :label="fun.getLanguageText($store.state.language, '从属公司')" prop="belongId">
        <el-select v-model="formData.belongId" :disabled="$route.query.id"
          :placeholder="fun.getLanguageText($store.state.language, '请选择')">
          <el-option v-for="item in companyList" :key="item.id" :label="item.companyName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="fun.getLanguageText($store.state.language, '国家')" prop="country">
        <el-select v-model="formData.country" filterable :placeholder="fun.getLanguageText($store.state.language, '请选择')"
          value-key="name" @change="changeCountry($event)">
          <el-option v-for="item in countryList" :key="item.id"
            :label="$store.state.language == 1 ? item.name : item.name_en" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="fun.getLanguageText($store.state.language, '省份')" prop="province">
        <el-select v-model="formData.province" filterable :placeholder="fun.getLanguageText($store.state.language, '请选择')"
          value-key="name" @change="changeProvince($event)">
          <el-option v-for="item in provinceList" :key="item.id"
            :label="$store.state.language == 1 ? item.name : item.name_en" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item :label="fun.getLanguageText($store.state.language, '城市')" prop="city">
        <el-select v-model="formData.city" filterable :placeholder="fun.getLanguageText($store.state.language, '请选择')">
          <el-option v-for="item in cityList" :key="item.id"
            :label="$store.state.language == 1 ? item.name : item.name_en" :value="item.id"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-button type="info" @click="cancel">{{ fun.getLanguageText($store.state.language, '取消') }}</el-button>
        <el-button type="primary" @click="create">{{ fun.getLanguageText($store.state.language, '确认') }}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      formData: {
        companyName: '', // 公司名称
        companyAddr: '', // 公司地址
        companyEmail: '', // 公司邮箱
        companyType: '', // 公司类型
        belongId: '', // 归属ID
        belong: '', // 归属公司
        country: '', // 国家ID
        province: '', // 省份ID
        city: '', // 城市ID
        id: '', // 修改功能编辑
      },
      companyList: [], // 公司列表
      roleList: [], // 用户类型列表
      companyTypeList: [], // 公司类型列表
      countryList: [], // 国家列表
      provinceList: [], // 省份列表
      cityList: [], // 城市列表
      countryPath: '', // 选中国家的path
      provincePath: '', // 选中省份的path
      rules: {
        companyName: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '公司名称不能为空'),
          trigger: 'blur'
        },
        {
          min: 2,
          message: this.fun.getLanguageText(this.$store.state.language, '公司名称不能小于5位'),
          trigger: 'blur'
        }
        ],
        companyAddr: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '公司地址不能为空'),
          trigger: 'blur'
        }],
        companyType: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '公司类型不能为空'),
          trigger: 'change'
        }],
        belongId: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '从属公司不能为空'),
          trigger: 'change'
        }],
        country: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '国家不能为空'),
          trigger: 'change'
        }],
        // province: [{
        //   required: true,
        //   message: '省份不能为空',
        //   trigger: 'change'
        // }],
        // city: [{
        //   required: true,
        //   message: '城市不能为空',
        //   trigger: 'change'
        // }],
        companyEmail: [{
          required: true,
          message: this.fun.getLanguageText(this.$store.state.language, '邮箱不能为空'),
          trigger: 'blur'
        }, {
          type: 'email',
          message: this.fun.getLanguageText(this.$store.state.language, '请输入正确的邮箱地址'),
          trigger: 'blur'
        }]
      },
    }
  },
  computed: {
    // 判断公司类型是否默认
    is_disabled_company_type: function () {
      if (this.$store.state.roleId === 3) { // 客户权限
        return "232323"
      }
      return false
    },
    // 判断是否具有从属公司
    is_belong: function () {
      if (this.$store.state.roleId === 1) { // 超管权限
        if (this.formData.companyType + "" === '1') {
          // 拉取全局管理员公司列表
          return true
        }
        if (this.formData.companyType + "" === '2') {
          // 拉取全局客户公司列表
          return true
        }
      } else if (this.$store.state.roleId === 2) { // 管理权限
        if (this.formData.companyType + "" === '2') {
          // 拉取当前账号可选客户公司
          return true
        }
      } else if (this.$store.state.roleId === 3) { // 客户权限
        return false
      }
      return false
    }
  },

  created() {
    if (this.$route.query.id) {
      this.formData.id = this.$route.query.id
      this.getEdit()
    }
    this.getCountryList()
    this.getSelectCom()
    // 读取用户权限
    console.log(this.$store.state.roleId)
    if (this.$store.state.roleId === 3) { // 客户权限
      this.formData.companyType = "2"
    }
  },
  methods: {
    // 省份被改变
    changeProvince(e, req) {
      console.log("改变省份", req, this.provinceList.length)
      this.provinceList.forEach(item => {
        if (item.id == e) {
          this.formData.province = item.id + ""
          // 设置筛选城市使用ID
          if (!req) {
            this.formData.city = ''
          }
          this.provincePath = item.path
          this.getCityList()
        }
      })
    },
    // 读取指定公司信息
    getEdit() {
      this.$http.post("company/edit", {
        id: this.formData.id
      }).then(res => {
        console.log("读取指定公司信息", res)
        if (res.data.code == 2000) {
          for (let key in this.formData) {
            if (key != "id") {
              this.formData[key] = res.data.data[key] + ""
            }
          }
          this.formData.country = res.data.data.countryId + ""
          this.formData.city = res.data.data.cityId + ""
          this.formData.province = res.data.data.provinceId + ""
          this.formData.belongId = res.data.data.belong + ""
          setTimeout(() => {
            this.changeCountry(this.formData.country, true)
          }, 500)
          setTimeout(() => {
            this.changeProvince(this.formData.province, true)
          }, 1000)
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取从属该公司
    getSelectCom() {
      this.$http.post("company/select", {}).then(res => {
        if (this.$store.state.roleId == 3) {
          this.companyList = [res.data.data]
          this.formData.belongId = res.data.data.id + ""
        }
        if (this.$store.state.roleId == 2) {
          this.companyList = res.data.data
        }
      }).catch(err => {
        console.log(err)
      })
    },

    cancel() {
      this.$router.push('/Home/UserList')
    },
    // 改变 国家
    changeCountry(e, req) {
      this.countryList.forEach(item => {
        if (item.id == e) {
          this.formData.country = item.id + ""
          // 设置筛选城市使用ID
          if (!req) {
            this.formData.city = ''
            this.formData.province = ''
          } else {

          }
          this.countryPath = item.path
          this.getProvinceList()
        }
      })
      console.log("改变国家", req)
    },
    // 读取用户类型列表
    getRoleList() {
      this.$http.post('role/list', {}).then(res => {
        if (res.data.msg == 'success') {
          console.log(res.data)
          this.roleList = res.data.data
        }
      }).catch(err => {
        this.getRoleList()
      })
    },
    // 改变公司类型
    changecompanyType(e) {
      console.log(this.formData.role_id)
      this.companyList = []
      this.formData.belongId = ''
      if (this.$store.state.roleId === 1) { // 超管权限
        if (this.formData.companyType + "" === '1') {
          // 拉取全局管理员公司列表
          this.getCompanyList(-1)
          return true
        }
        if (this.formData.companyType + "" === '2') {
          // 拉取全局客户公司列表
          this.getCompanyList(1)
          return true
        }
      } else if (this.$store.state.roleId === 2) { // 管理权限
        if (this.formData.companyType + "" === '2') {
          // 拉取当前账号可选客户公司
          this.getCompanyList(1)
          return true
        }
      } else if (this.$store.state.roleId === 3) { // 客户权限
        return false
      }
      return false
    },
    // 创建用户
    async create() {
      let bool = false
      await this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          bool = true
        } else {
          console.log('error submit!!!');
          return false;
        }
      });
      if (!bool) {
        return
      }
      console.log(this.formData.id)
      if (this.formData.id == "undefined") {
        this.formData.id = ""
      }
      this.$http.post('company/add', this.formData).then(res => {
        console.log(res)
        if (res.data.msg === "success") {
          this.$message({
            type: 'success',
            message: this.fun.getLanguageText(this.$store.state.language, '添加账户成功')
          });
          this.cancel()
          return
          // 返回上一界面
        }
        this.$message({
          type: 'warning',
          message: this.fun.getLanguageText(this.$store.state.language, res.data.msg)
        });
      }).catch(err => {
        console.log(err)

      })
    },
    // 获取公司列表
    getCompanyList(type) {
      this.$http.post("company/list", {
        companyType: type,
        pageSize: 100
      }).then(res => {
        console.log(res.data)
        if (res.data.msg === "success") {
          this.companyList = res.data.data.info
        }
      }).catch(err => {
        console.log(err)
      })
    },
    // 获取城市列表
    getCityList() {
      this.$http.post('region/select-city', {
        path: this.provincePath
      }).then(res => {
        if (res.data.msg == 'success') {
          console.log('获取城市列表')
          this.cityList = res.data.data
        }
      }).catch(err => {
        // this.getCityList()
      })
    },
    // 获取省份列表
    getProvinceList() {
      this.$http.post('region/select-province', {
        path: this.countryPath,
      }).then(res => {
        if (res.data.msg == 'success') {
          console.log('获取省份列表')
          this.provinceList = res.data.data
        }
      }).catch(err => {
      })
    },
    // 获取国家列表
    getCountryList() {
      this.$http.post('region/select-country', {}).then(res => {
        if (res.data.msg == 'success') {
          console.log('获取国家列表', res.data.data)
          this.countryList = res.data.data
        }
      }).catch(err => {
        // this.getCountryList()
      })
    }
  }
}
</script>

<style lang="less" scoped>
.el-form-item {
  .el-input {
    width: 500px;
  }

  .el-select {
    width: 500px;
  }

  .el-button {
    width: 200px;
    margin-right: 90px;
  }
}
</style>
